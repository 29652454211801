<template>
    <div class="ecommerce-application">
        <section id="knowledge-base-search">
            <b-card no-body class="knowledge-base-bg text-center" :style="`background: linear-gradient(0deg, rgba(0,0,0,0.2), rgba(0,0,0,0.2)), url(${searchBanner}) repeat;`">
                <b-card-body class="card-body">
                    <h2 class="text-dark font-weight-bolder" :style="textStroke">Search What Do You Want</h2>
                    <b-card-text class="mb-2">
                        <span class="font-weight-bolder" :style="textStroke"
                            >Popular searches: Phishing detection mechanisms, Krebs-Booter</span
                        >
                    </b-card-text>
                    <b-form class="kb-search-input d-flex justify-content-center align-items-center" @submit.prevent>
                        <b-input-group class="input-group-merge m-0">
                            <b-input-group-prepend is-text>
                                <feather-icon icon="SearchIcon" />
                            </b-input-group-prepend>
                            <b-form-input
                                id="searchbar"
                                v-model="searchQuery"
                                @input="isTyping = true"
                                placeholder="Search in soclib"
                                autocomplete="off"
                            />
                        </b-input-group>
                        <b-spinner
                            class="ml-1"
                            type="grow"
                            small
                            v-show="isLoading"
                            variant="primary"
                            label="Searching..."
                        />
                    </b-form>
                    <div class="my-favorites mt-1">
                        <b-button
                            @click="favoritesHandler"
                            :variant="favoritesFilter ? 'gradient-primary' : 'light-light'"
                            size="sm"
                        >
                            <span class="mr-50">
                                {{ favoritesFilter ? "Show All Posts" : "My Favorite Posts" }}
                            </span>
                            <feather-icon :icon="favoritesFilter ? 'ImageIcon' : 'HeartIcon'" size="13" />
                        </b-button>
                    </div>
                </b-card-body>
            </b-card>
        </section>
        <div class="row">
            <div class="col-md-3">
                <b-card class="w-100">
                    <h5 class="filter-heading font-weight-bolder">Filters</h5>
                    <div class="row">
                        <div class="col-md-12" v-for="(filter, index) in filters" :key="index">
                            <b-form-group>
                                <label>
                                    {{ filter.title }}
                                </label>
                                <v-select
                                    multiple
                                    label="name"
                                    placeholder="Choose..."
                                    v-model="$data[filter.model]"
                                    :options="$data[filter.option]"
                                />
                            </b-form-group>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-6">
                            <b-button @click="filterPostsHandler" variant="primary" block> Filter </b-button>
                        </div>
                        <div class="col-xl-6">
                            <b-button
                                @click="resetFilterHandler"
                                variant="outline-secondary"
                                block
                                class="mt-1 mt-xl-0"
                            >
                                Reset
                            </b-button>
                        </div>
                    </div>
                </b-card>
            </div>
            <div class="col-md-9">
                <section class="list-view mt-0 mb-2" v-if="posts.length > 0">
                    <b-card class="ecommerce-card" no-body v-for="post in posts" :key="post.id">
                        <div class="item-img text-center">
                            <b-link :to="{ name: 'posts-show', params: { id: post.id } }">
                                <b-img
                                    fluid
                                    class="card-img-top"
                                    :src="post.featured_image ? post.featured_image : thumbnailImage"
                                />
                            </b-link>
                        </div>
                        <b-card-body>
                            <div class="item-wrapper">
                                <div>
                                    <h6 class="item-price">$1,890</h6>
                                </div>
                            </div>
                            <h6 class="item-name mb-2">
                                <b-link class="text-body" :to="{ name: 'posts-show', params: { id: post.id } }">
                                    {{ post.title }}
                                </b-link>
                            </h6>
                            <b-card-text
                                class="item-description"
                                v-html="stringLimit(post.description, 300)"
                            ></b-card-text>
                        </b-card-body>
                        <div class="item-options text-center">
                            <div class="font-weight-bolder" v-if="Object.keys(post.properties).length > 0">
                                <div v-for="(property, index) in post.properties" :key="index" class="text-left mb-2">
                                    <h6 class="font-weight-bolder mb-0">
                                        {{ toStartCase(index, "_", " ") }}
                                    </h6>
                                    <div>
                                        <small v-for="(attr, index) in property" :key="attr.id">
                                            {{ attr.name }} <small v-if="index !== property.length - 1"> | </small>
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="font-weight-bolder text-muted" v-else>Properties Not Found</div>
                        </div>
                    </b-card>
                    <b-row>
                        <b-col cols="12">
                            <b-pagination
                                v-model="pagination.page"
                                :total-rows="pagination.total"
                                :per-page="pagination.perPage"
                                @change="pageChangeHandler"
                                first-number
                                align="center"
                                last-number
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </section>
                <section v-else>
                    <b-card class="ecommerce-card p-3 text-center">
                        <h3 class="text-secondary mb-0">Posts not found</h3>
                    </b-card>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import vSelect from "vue-select";
import {
    BRow,
    BCol,
    BCard,
    BImg,
    BButton,
    BCardBody,
    BCardText,
    BAvatar,
    BForm,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BSpinner,
    BFormRadioGroup,
    BLink,
    BFormInput,
    BPagination,
} from "bootstrap-vue";
import axios from "axios";
export default {
    components: {
        BRow,
        BCol,
        BCard,
        BImg,
        BButton,
        BCardBody,
        BCardText,
        BAvatar,
        BForm,
        BInputGroup,
        BFormGroup,
        BFormRadioGroup,
        BLink,
        BInputGroupPrepend,
        BSpinner,
        BFormRadioGroup,
        BFormInput,
        BPagination,
        vSelect,
    },
    data() {
        return {
            // favorites
            favoritesFilter: false,
            // search
            isTyping: false,
            isLoading: false,
            searchQuery: undefined,
            // filter
            securityJourney: [],
            securityJourneyOptions: [],
            securityUseCaseApplicability: [],
            securityUseCaseApplicabilityOptions: [],
            dataModels: [],
            dataModelsOptions: [],
            dataSource: [],
            dataSourceOptions: [],
            confidence: [],
            confidenceOptions: [],
            securityDomain: [],
            securityDomainOptions: [],
            severity: [],
            severityOptions: [],
            cis20: [],
            cis20Options: [],
            killChain: [],
            killChainOptions: [],
            miterAttack: [],
            miterAttackOptions: [],
            nist: [],
            nistOptions: [],
            filters: [],
            titleQuery: this.$route.query.title,
            tagsQuery: this.$route.query.tags,
            // posts
            posts: [],
            // pagination
            pagination: {
                page: 1,
                total: undefined,
                perPage: undefined,
            },
        };
    },
    watch: {
        searchQuery: _.debounce(function () {
            this.resetDefault();
        }, 500),
        isTyping: function (value) {
            if (!value) {
                this.isLoading = true;
                this.filterPostsHandler();
            }
        },
    },
    computed: {
        thumbnailImage() {
            return require("@/assets/images/posts/thumbnail.png");
        },
        searchBanner() {
            if (this.$store.state.appConfig.layout.skin === "light") {
                return require("@/assets/images/banner/splunk-light-low.jpg");
            } else {
                return require("@/assets/images/banner/splunk-dark-low.jpg");
            }
        },
        textStroke() {
            if (this.$store.state.appConfig.layout.skin === "light") {
                return "text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;";
            } else {
                return "text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;";
            }
        },
    },
    mounted() {
        console.log(this.searchBanner);
        this.searchQuery = this.titleQuery;
        this.getProperties();
        this.getPosts();
    },
    methods: {
        resetDefault() {
            this.pagination.page = 1;
            this.titleQuery = undefined;
            this.tagsQuery = undefined;
            this.isTyping = false;
        },
        getPosts(params = {}) {
            let time = 2000;
            if (!this.isLoading) {
                time = 0;
            }
            setTimeout(() => {
                axios
                    .get(`${process.env.VUE_APP_URL}/posts/client`, {
                        params: {
                            ...params,
                            per_page: 4,
                            tags: this.tagsQuery,
                            title: this.titleQuery,
                            page: this.pagination.page,
                        },
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                        },
                    })
                    .then(({ data }) => {
                        this.pagination.perPage = data.per_page;
                        this.pagination.total = data.total;

                        this.posts = _.map(data.data, (item) => {
                            return {
                                ...item,
                                properties: _.pick(_.groupBy(item.values, "attribute.name"), [
                                    "security_journey",
                                    "cis_20",
                                    "data_models",
                                ]),
                            };
                        });
                        this.isLoading = false;
                    });
            }, time);
        },
        getProperties() {
            const params = {
                filters: [
                    "security_journey",
                    "data_models",
                    "security_journey",
                    "security_use_case_applicability",
                    "data_models",
                    "data_source",
                    "confidence",
                    "security_domain",
                    "severity",
                    "cis_20",
                    "kill_chain",
                    "nist",
                ],
            };
            // get attributes
            axios
                .get(`${process.env.VUE_APP_URL}/attributes`, {
                    params,
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    },
                })
                .then(({ data }) => {
                    _.map(data, (item, index) => {
                        this.filters.push({
                            title: this.toStartCase(index, "_", " "),
                            model: _.camelCase(index),
                            option: `${_.camelCase(index)}Options`,
                        });
                        this[`${_.camelCase(index)}Options`] = item[0].values;
                    });
                    this.filters.push({
                        model: "miterAttack",
                        option: "miterAttackOptions",
                        title: "Mitre Att&ck",
                    });
                });

            // get techniques
            axios
                .get(`${process.env.VUE_APP_URL}/techniques`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    },
                })
                .then((response) => {
                    this.miterAttackOptions = _.map(response.data, (item) => {
                        return { ...item, name: item.technique_id };
                    });
                });
        },
        filterPostsHandler() {
            let params = {};
            _.map(this.filters, (item) => {
                params[_.snakeCase(item.title)] = _.map(this[item.model], "id");
            });

            const prm = _.assign(params, {
                mitre_attack: params.mitre_att_ck,
                search: this.searchQuery,
            });

            this.getPosts(_.omit(prm, ["mitre_att_ck"]));
            // this.resetPagination();
        },
        resetFilterHandler() {
            _.map(this.filters, (item) => {
                this[item.model] = [];
            });
            this.getPosts({ search: this.searchQuery });
        },
        pageChangeHandler(value) {
            this.pagination.page = value;
            this.favoritesFilter ? this.getFavoritePosts() : this.filterPostsHandler();
        },
        resetPagination() {
            this.pagination.page = 1;
        },
        stringLimit(str, limit) {
            return _.truncate(str, {
                length: limit,
                omission: " ...",
            });
        },
        toStartCase(value, delimiter, separator) {
            return _.startCase(_.join(_.split(value, delimiter), separator));
        },
        favoritesHandler() {
            this.resetPagination();
            this.favoritesFilter = !this.favoritesFilter;
            this.favoritesFilter ? this.getFavoritePosts() : this.getPosts();
        },
        getFavoritePosts(params) {
            axios
                .get(`${process.env.VUE_APP_URL}/favorites`, {
                    params: { page: this.pagination.page },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    },
                })
                .then(({ data }) => {
                    this.pagination.perPage = data.per_page;
                    this.pagination.total = data.total;

                    this.posts = _.map(data.data, (item) => {
                        return {
                            ...item,
                            properties: _.pick(_.groupBy(item.values, "attribute.name"), [
                                "security_journey",
                                "cis_20",
                                "data_models",
                            ]),
                        };
                    });
                });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-knowledge-base.scss";
@import "@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
    .btn {
        display: flex;
        align-items: center;
    }
}

.card-img,
.card-img-top {
    border-radius: calc(0.428rem - 1px) !important;
}
// posts list
@media only screen and (min-width: 992px) {
    .ecommerce-application {
        & .ecommerce-card {
            & .item-img {
                margin: 40px;
                height: unset !important;
            }
            & .card-body {
                padding-top: 40px !important;
            }
        }
    }
}
@media only screen and (max-width: 768px) {
    .ecommerce-application .list-view {
        & .ecommerce-card {
            & .item-img {
                padding: 0px !important;
            }
        }
    }
}
.btn-light-light {
    background-color: rgba($color: white, $alpha: 1);
}
</style>
